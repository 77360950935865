import { saveInLocal } from "helpers/localStorage";

import actionTypes from "../consts/actionTypes";
import routes from "../consts/routes";

// Actions for VOD video
export function testAsset(assetId) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.testAsset,
          params: {
            idAsset: assetId,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.TEST_ASSET_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.TEST_ASSET_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          // Do something if the httpCode if >= 300
          resolve(meta);
          return {
            type: actionTypes.TEST_ASSET_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}

export function buyAsset(assetId, price, voucherCode, paymentData, reusePaymentData, purchasePin) {
  const params = {};

  if (assetId) {
    params.idAsset = assetId;
  }

  if (price) {
    params.price = price;
  } else {
    params.price = 0;
  }

  if (voucherCode) {
    params.voucherCode = voucherCode;
  }

  if (paymentData) {
    params.paymentData = paymentData;
  }
  if (reusePaymentData) {
    params.reusePaymentInfo = reusePaymentData;
  }
  if (purchasePin) {
    params.purchasePin = purchasePin;
  }

  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.buyAsset,
          params: params,
        },
        onStart: () => {
          return {
            type: actionTypes.BUY_ASSET_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          saveInLocal("TucanoOrderId", payload?.orderId);
          return {
            type: actionTypes.BUY_ASSET_SUCCESS,
            meta,
            payload,
          };
        },
        onError: (payload, meta) => {
          // Do something if the httpCode if >= 300
          resolve(meta);
          return {
            type: actionTypes.BUY_ASSET_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}

export function readAsset(assetId, audioLang = "eng", subtitleLang = "non") {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.readAsset,
          params: {
            idAsset: assetId,
            idAudioLang: audioLang,
            idSubtitleLang: subtitleLang,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.READ_ASSET_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.READ_ASSET_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          // Do something if the httpCode if >= 300
          resolve(payload);
          return {
            type: actionTypes.READ_ASSET_ERROR,
            payload,
          };
        },
      });
    });
  };
}

export function listAssetTags(assetId) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "GET",
          url: routes.listAssetTags,
          params: {
            idAsset: assetId,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.LIST_ASSET_TAGS_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          let tags = {};
          if (payload.tags.length > 0) {
            payload.tags.forEach((item) => {
              const { type, timestamp } = item;

              if (!tags[type]) {
                tags[type] = [];
              }

              tags[type].push(timestamp);
            });

            // Sort the arrays by timestamp in ascending order
            Object.keys(tags).forEach((type) => {
              tags[type].sort((a, b) => a - b);
            });
          }
          resolve(tags);
          return {
            type: actionTypes.LIST_ASSET_TAGS_SUCCESS,
            payload: tags,
          };
        },
        onError: (payload, meta) => {
          // Do something if the httpCode if >= 300
          resolve(payload);
          return {
            type: actionTypes.LIST_ASSET_TAGS_ERROR,
            payload,
          };
        },
      });
    });
  };
}

export function readRecord(recordId, languageId = "eng") {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "GET",
          url: `${routes.manageNPVRRecord}/${recordId}/read/${languageId}`,
        },
        onStart: () => {
          return {
            type: actionTypes.READ_ASSET_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.READ_ASSET_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          // Do something if the httpCode if >= 300
          resolve(payload);
          return {
            type: actionTypes.READ_ASSET_ERROR,
            payload,
          };
        },
      });
    });
  };
}

export function getVodLicense(assetId, audioLang = "eng", subtitleLang = "non") {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.getVodLicense,
          params: {
            idAsset: assetId,
            idAudioLang: audioLang,
            idSubtitleLang: subtitleLang,
            asUrl: true,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.GET_VOD_LICENSE_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.GET_VOD_LICENSE_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          // Do something if the httpCode if >= 300
          resolve(meta);
          return {
            type: actionTypes.GET_VOD_LICENSE_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function seekAsset(assetId, position) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.seekAsset,
          params: {
            idAsset: assetId,
            seek: position,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.POST_SEEK_ASSET_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.POST_SEEK_ASSET_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          // Do something if the httpCode if >= 300
          resolve(meta);
          return {
            type: actionTypes.POST_SEEK_ASSET_ERROR,
            meta,
          };
        },
      });
    });
  };
}

// Actions for Live video
export function channelStream(idChannel, startUtc, endUtc) {
  return (dispatch) => {
    return new Promise((resolve) => {
      const params = {
        idChannel: idChannel,
      };
      if (startUtc) {
        params.startUtc = startUtc;
      }
      if (endUtc) {
        params.startUtc = endUtc;
      }

      dispatch({
        request: {
          method: "POST",
          url: routes.channelStream,
          params,
        },
        onStart: () => {
          return {
            type: actionTypes.POST_CHANNEL_STREAM_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.POST_CHANNEL_STREAM_SUCCESS,
            meta,
            payload,
          };
        },
        onError: (payload, meta) => {
          // Do something if the httpCode if >= 300
          resolve(meta);
          return {
            type: actionTypes.POST_CHANNEL_STREAM_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}

export function askLicenseWV(idChannel) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.askLicenseWV,
          params: {
            idChannel,
            asUrl: true,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.POST_ASK_LICENSE_WV_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.POST_ASK_LICENSE_WV_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          // Do something if the httpCode if >= 300
          resolve(meta);
          return {
            type: actionTypes.POST_ASK_LICENSE_WV_ERROR,
            meta,
          };
        },
      });
    });
  };
}
export function askLicenseFP(idChannel) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.askLicenseFP,
          params: {
            idChannel,
            asUrl: true,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.POST_ASK_LICENSE_WV_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.POST_ASK_LICENSE_WV_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          // Do something if the httpCode if >= 300
          resolve(meta);
          return {
            type: actionTypes.POST_ASK_LICENSE_WV_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function resetPlayerInfos() {
  return {
    type: actionTypes.RESET_PLAYER_INFOS,
  };
}

export function restartPlayerLive() {
  return {
    type: actionTypes.RESTART_PLAYER_LIVE,
  };
}

export function setTrailerStatus(status) {
  return {
    type: actionTypes.SET_TRAILER_STATUS,
    payload: status,
  };
}
